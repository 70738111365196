import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form} from 'react-bootstrap';
import '../../theme/Styles.css';
import { Company } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { CreateFirmModal } from '../../components/Common/Modal_FirmCreated';
import { useNavigate } from "react-router-dom";

export const CreateCompany = () => {

    const [show, setShow] = React.useState(false);

    const handleClose = () => {setShow(false);navigate(-1);}
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    async function submitValues (values) {
        values.preventDefault();
        console.log(values);
        const valuesList = [];
        for(let i=0;i<(values.target).length-1;i++){
            valuesList.push(values.target[i].value);
        }
        
        try {
            await DataStore.save(
            new Company({
                "name": valuesList[0],
                "SGKregistrationNumber": valuesList[1],
                "numberOfActiveEmployees": parseInt(valuesList[2])
            })
        );}
        catch(e) {
            console.log(e);
        }

        handleShow();

    };
    return (
        <>

        {CreateFirmModal(show, handleClose)}
        <Container fluid className='container-custom'>
            <Row className='heighting'>
            { <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col> }

                <Col sm={7} className='main-col-custom mt-3'>
                
                <Form onSubmit={submitValues}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="deneme 1">
                            <Form.Label>Yeni Fabrika ismi</Form.Label>
                            <Form.Control autoComplete="off"/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="deneme 1">
                            <Form.Label>SGK Sicil No</Form.Label>
                            <Form.Control autoComplete="off"/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="deneme 1">
                            <Form.Label>Aktif Çalışan Sayısı</Form.Label>
                            <Form.Control type="number" autoComplete="off"/>
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Button className='mb-4' variant="primary" type="submit">Gönder</Button>
                    </Form.Group>
                </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        </>
    );
}
import React from "react";
import { groupBy } from "lodash";
import {
    Highlighter,
    Menu,
    MenuItem,
    Typeahead,
  } from 'react-bootstrap-typeahead';

export const SearchInput = (setSubTitle, setTitle) => {
    const options = [ 
        { subtitle: "Yasalar", title: "Mevzuatlar"},
        { subtitle: "Tüzükler", title: "Mevzuatlar"},
        { subtitle: "Yönetmelikler", title: "Mevzuatlar"},
        { subtitle: "Tebliğler", title: "Mevzuatlar"},
        { subtitle: "Sağlık Rehberleri", title: "Sağlık"},
        { subtitle: "Deri Sektörü", title: "Sektörel"},
        { subtitle: "Gıda Sektörü", title: "Sektörel"},
        { subtitle: "İnşaat Sektörü", title: "Sektörel"},
        { subtitle: "Kimya Sektörü", title: "Sektörel"},
        { subtitle: "Maden Sektörü", title: "Sektörel"},
        { subtitle: "Metal Sektörü", title: "Sektörel"},
        { subtitle: "Mobilya Sektörü", title: "Sektörel"},
        { subtitle: "Tekstil Sektörü", title: "Sektörel"},
    ]

    const props = {};

        props.renderMenu = (
            results,
            {
                newSelectionPrefix,
                paginationText,
                renderMenuItemChildren,
                ...menuProps
            },
            state
        ) => {
            let index = 0;
            const items = Object.keys(groupBy(results, 'title')).sort().map((title) => (
                <React.Fragment key={title}>
                    {index !== 0 && 
                    <Menu.Divider />}
                    <Menu.Header>
                        {title}
                    </Menu.Header>
                    
                    {groupBy(results, 'title')[title].map((i) => {
                        const item = (
                            <MenuItem key={index} option={i} position={index} onClick={()=> {
                                setSubTitle(i.subtitle);
                                setTitle(i.title)}}>
                                <Highlighter search={state.text}>
                                    {i.subtitle}
                                </Highlighter>
                            </MenuItem>
                        );
      
                    index += 1;
                    return item;
                    })}
                </React.Fragment>
            ));
  
          return <Menu {...menuProps}>{items}</Menu>;
        };
  
      return (
          <>
              <Typeahead
                  {...props}
                  id="rendering-example"
                  labelKey="subtitle"
                  options={options}
                  placeholder="Bir konu başlığı belirleyiniz"
              />
          </>
      );
  };
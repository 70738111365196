import { DataStore } from 'aws-amplify';
import { User } from '../models';

export const updatePost = async(subID, identityID) => {
    const original = await DataStore.query(User, subID);
    if(original.Identity_ID === null){
        await DataStore.save(User.copyOf(original, updated => { updated.Identity_ID = identityID }));
    }
    else{
    }
  }
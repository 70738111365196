import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../theme/Styles.css';
import { useNavigate, useLocation } from "react-router-dom";
import { CreateHomePageCards } from '../../components/Components_Page_Home/Cards';

export const ListForms = () => {
    
    const navigate = useNavigate();
    let location = useLocation();

    return (
        <>
            <Container fluid className='container-custom'>
                <Row className='heighting'>

                    <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col>
                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>
                            {CreateHomePageCards(location.state)}
                        </Row>
                    </Col>
            
                <Col></Col>

                </Row>
            </Container>
        </>
    );
}
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { FormSendInfo } from '../../components/Common/Modal_FormSend';
import { Auth } from 'aws-amplify';
import { Send } from '../../components/Common/SendForm&FileTemplate';

export const CreateForm = () => {

    let location = useLocation()
    console.log(location.state)

    const [ infos, setInfos ] = React.useState({
        show: false,
        uploadFile: null,
        identityID: null,
        subID: null,
        location: useLocation(),
        navigate: useNavigate(),
    })

    const [ message, setMessage ] = React.useState('')
    const [ messageTitle, setMessageTitle ] = React.useState('')

    const handleModalClose = () => {setInfos((prevState) => ({...prevState, show: false })); 
            if(messageTitle!=="Hata!")infos.navigate(-1);}
    const handleModalShow = () => setInfos((prevState) => ({...prevState, show: true }));
    const handleFiles = (file) => setInfos((prevState) => ({...prevState, uploadFile: file }));

    React.useEffect(() => {
        Auth.currentCredentials().then(data => setInfos((prevState) => ({...prevState, identityID: data.identityId })))
        Auth.currentSession().then((e) => setInfos((prevState) => ({...prevState, subID: e.getIdToken().payload.sub })))
    } ,[])

    async function submitValues (values) {
        values.preventDefault();
        const valuesList = []
        for(let i=0;i<(values.target).length-1;i++){
            valuesList.push(values.target[i].value);
        } 
        console.log(valuesList)
        const hasEmptyValue = valuesList.some((value) => value.trim() === '');
        if (hasEmptyValue) {
            setMessageTitle("Hata!")
            setMessage("Bilgiler boş olmamalı")
            handleModalShow()
        }
        else {
            setMessageTitle("Form Gönderildi!")
            Send( infos, values )
            handleModalShow()
        }
    };

    return (
        <>
        {FormSendInfo(infos["show"], handleModalClose, messageTitle, message)}

        <Container fluid className='container-custom'>
            <Row className='heighting'>
                <Col><Button variant="primary" onClick={() => infos.navigate(-1)}>Geri dön</Button></Col>
                <Col sm={7} className='main-col-custom mt-3'>
                <h5>{location.state[0].cards.name} formu oluştur</h5>
                <Form onSubmit={submitValues}>

                            &ensp;
                            <div className='row'>

                                <h6>İşyerinin:</h6>
                                <Form.Group as={Col} controlId="Tarihi">
                                    <Form.Label>Tarih</Form.Label>
                                    <Form.Control autoComplete="off" type="date"/>
                                </Form.Group> 
                                
                                <Form.Group as={Col} controlId="Adresi">
                                    <Form.Label>Seri Numarası</Form.Label>
                                    <Form.Control type="number"/>
                                </Form.Group>
                            </div>
                            &ensp;

                            <Form.Group as={Col} controlId="Yükle">
                                <Form.Label>Dosya Yükle</Form.Label>
                                <Form.Control autoComplete="off" type="file" accept=".pdf,.docx,.txt,.img" onChange={(e) => handleFiles(e.target.files[0])}/>
                            </Form.Group>

                            &ensp;
                            <Row>
                                <Col>
                                    <Button className='mb-4' variant="primary" type="submit">Gönder</Button>
                                </Col>                                  
                            </Row>
                    </Form>
                            
                </Col>
                <Col></Col>
            </Row>
        </Container>
        </>
    )
}
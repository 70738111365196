import { Row, Col, Button, Container,  Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { Model_Fabrikalar } from "../../../api/Model_Read";
import { ExpandableRowTable } from "../../../components/Common/Table_ExpandableRows";

export const Workplace = () => {

    const navigate = useNavigate();
    const [ model, setModel ] = React.useState(null);

    React.useEffect(()=>{
        async function getModelData(){
            const data = await Model_Fabrikalar();
            setModel(data);
        }
        getModelData()
        },[]);

    return(
        <>
        <Container fluid className='container-custom'>
            <Row className='heighting'>
            <Col></Col>

                <Col sm={7} fluid className='main-col-custom mt-4'>
                    <Row className="firm-header">İşyeri İşlemleri</Row>
                    &ensp;
                    <Row>
                            <Col style={{maxWidth:100}}>
                                <Button style={{"width":"100%"}} onClick={() => navigate("/Yeni-Fabrika-Ekle/")}>Ekle</Button>
                            </Col>
                            <Col style={{maxWidth:200}}>
                                <Button disabled style={{"width":"100%"}}>İçe Aktar</Button>
                            </Col>
                            <Col style={{maxWidth:200}}>
                                <Button disabled style={{"width":"100%"}}>Çalışanları Göster</Button>
                            </Col>
                    </Row>
                    <Row>

                    </Row>
                    &ensp;
                    <Row className="tablesearch">
                    
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Ara"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button variant="outline-success">Search</Button>
                    </Form>

                    </Row>
                    
                    <Row style={{padding:0}}>

                    {ExpandableRowTable(model, setModel)}
                        
                    </Row>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        </>
    )
}
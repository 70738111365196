import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { DataStore } from "aws-amplify";
import { Company } from "../../models";
const lodashClonedeep = require('lodash.clonedeep');

export const EditFirmInfo = (info, show, setShow, setModel, model) => {

    async function submitValues (values) {
        values.preventDefault();
        const valuesList = []
        for(let i=0;i<(values.target).length-1;i++){
            valuesList.push(values.target[i].value);
        }
        
        updateModel(info["id"],valuesList)
        setShow(false)
        }

    const updateLocalModal = (id, values) => {
        const newState = model.map(company => {
            console.log(typeof(company))
            if (company.id === id) {
                company = lodashClonedeep(company)
                company.Name = values[0]
                company.SGK_Sicil_No = values[1]
                company.Aktif_Calisan_Sayisi = values[2]
                return company
            }
            return company; 
        })
        setModel(newState)
    };

    async function updateModel(id, newValues) {
        try{
            const original = info;
            await DataStore.save(
                Company.copyOf(original, updated => {
                updated.Name = newValues[0]
                updated.SGK_Sicil_No = newValues[1]
                updated.Aktif_Calisan_Sayisi = parseInt(newValues[2])
                })
            );
            updateLocalModal(id, newValues)
        }catch(e){
            console.log(e)
        }
    }


    return(
        <Modal show={show} onHide={()=> setShow(false)} >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submitValues}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="deneme 1">
                            <Form.Label>Yeni Fabrika ismi</Form.Label>
                            <Form.Control defaultValue={info["FabrikaAdi"]} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="deneme 2">
                            <Form.Label>SGK Sicil No</Form.Label>
                            <Form.Control defaultValue={info["SGKsicilno"]}/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="deneme 2">
                            <Form.Label>Aktif Çalışan Sayısı</Form.Label>
                            <Form.Control defaultValue={info["AktifCalisanSayisi"]} type="number"/>
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Button className='mb-4' variant="primary" type="submit">Düzenle</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
import { Storage, DataStore, SortDirection, Predicates } from 'aws-amplify';
import { Button, Spinner } from 'react-bootstrap';
import { User, Form, Document } from '../../models';

const downloadBlob = (blob, filename, setInfos) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const file = getFilename(filename)
    a.href = url;
    a.download = file || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    setInfos((prevState) => ({...prevState, spinnerCreate: false}))
    return a;
}

const getFilename = (filename) => {
    const resultArray = filename.split("/");
    return resultArray[resultArray.length-1]
}

export const CreateDownloadFilesButton = (infos, setInfos
  
    // ID, spinnerCreate, isSpinnerCreate, userID
    ) => {
    async function download(){
        const formInfo = await DataStore.query(Form, (c) => c.id.eq(infos.cardInfo.id))
        const userInfo = await DataStore.query(User, (c) => c.id.eq(formInfo[0].userID))

        const documentInfo = await DataStore.query(Document, (c) => c.formID.eq(formInfo[0].id))

        setInfos((prevState) => ({...prevState, spinnerCreate: true}))
        // isSpinnerCreate(true)
        const result = await Storage.list(documentInfo[0].path, { level: 'protected' , identityId: userInfo[0]["Identity_ID"]});
        const result2 = await Storage.get(result.results[0]["key"], { download: true, level: 'protected', identityId: userInfo[0]["Identity_ID"]});
        downloadBlob(result2.Body, result.results[0]["key"], setInfos);       
    };

    return(
        <Button onClick={()=> download()} disabled={infos.spinnerCreate}>
            {infos.spinnerCreate ? (<Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />):(
                    <>Dosyaları indir</>
                )}
            </Button>
    )
}
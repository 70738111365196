import { Auth } from 'aws-amplify';

export const updateGroupAndAdminStatus = async (setInfos) => {
  try {
    console.log("test")
    const data = await Auth.currentAuthenticatedUser();
    const group = data.signInUserSession.accessToken.payload['cognito:groups'][0];

    setInfos((prevState) => ({
      ...prevState,
      group: group,
      isAdmin: checkAdminGroup(group),
    }));
  } catch (error) {
    console.log(error);
  }
};

export const checkAdminGroup = (group) => {
    if(group === 'Admin')
        return true
    else
        return false
};

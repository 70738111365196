import { Card, Spinner } from 'react-bootstrap';
import * as React from 'react';
import {Modal_Form} from '../Common/Modal_Cards'


export const CreateCards = (values) => {

    const [ infos, setInfos ] = React.useState({
        firmInfo: values.FirmInfo,
        cardInfo: null,
        show: false,
        spinnerCreate: false,
        load: true,
        showNoData: false,
        userID: values.userID,
        formID: values.formID,
    })

    const clicked = async(e, setInfos ) => {
        await setInfos((prevState) => ({...prevState, cardInfo: e, userID: values.userID, show: true}))
    }

    React.useEffect(() => {        
        const timer = setTimeout(() => {
            setInfos((prevState) => ({...prevState, load: false}))
          }, 1000);        

        const modelCheck = () => {
            if(values.model===null || values.model.length === 0)
                setInfos((prevState) => ({...prevState, showNoData: true}))
            else
                setInfos((prevState) => ({...prevState, showNoData: false}))
        }
        modelCheck()
   
        return () => clearTimeout(timer);
        
    }, [values.model])

        return(
            <>
            {values.model && Modal_Form(infos, setInfos)}
            {infos.load ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : ( infos.showNoData ? (
                <>Kayıt bulunamadı.</>
            ) : (
                values.model && values.model.map((e) =>{
                    return(
                        <Card key={e.id} className="Cards" style={{ cursor: "pointer" }} onClick={() => {clicked(e, setInfos)}}>
                            <Card.Body>
                                <Card.Text><span className="Bold">Oluşturulma Tarihi:</span> {new Date(e.createdAt).toLocaleDateString()}</Card.Text>
                                <Card.Text><span className="Bold">Dosya Adı:</span> {new Date(e.createdAt).toLocaleDateString()}</Card.Text>
                            </Card.Body>
                        </Card>
                    )})
            ))} 
        </>
        )

}
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import '../../theme/Styles.css';
import * as Models from '../../api/Model_Read';
import { Auth, Hub } from 'aws-amplify';

export const ListCompany = () => {

    const navigate = useNavigate();    
    const [ names, setNames ] = React.useState(null);
    const [ group, setGroup ] = React.useState(null);
    const [ isAdmin, setAdmin ] = React.useState(false);
    const [ eventListener, setEventListener ] = React.useState(null)
    const [ user, setUser ] = React.useState(null)

    Auth.currentAuthenticatedUser().then(data => setGroup(data.signInUserSession.accessToken.payload['cognito:groups'][0]));
    Auth.currentSession().then((data) =>  setUser(data.getIdToken().payload.sub ))

    React.useEffect(()=>{
        Hub.listen("datastore", async (capsule) => {
                const {
                    payload: { event, data },
                } = capsule;
                setEventListener(event)
            });

        async function getModelData(){
            try{
                if (user != null){
                    const data = await Models.Model_Fabrikalar(user)
                    setNames(data)
                }
            } catch (e) {
                console.log(e)
            }
            if( group === "Admin" ){
                setAdmin(true)
            }
        }
        getModelData()
     },[group, user]);
    
    return(
        <>
            <Container fluid className='container-custom'>
                <Row className='heighting'>

                    <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col>
                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>
                            <Col>
                            { isAdmin ? (
                                <Button variant="primary" as={Link} to={"/Yeni-Fabrika-Ekle/"}>Yeni Fabrika Ekle</Button>
                                ) : (
                                <></>
                            )}</Col>                
                        </Row>
                        &ensp;
                        <Row>
                        {(eventListener === "ready" || names) && names.map((company) => {
                            return(
                                <Col key={company.name}>
                                    <Nav.Link className="Button_Main" as={Link} 
                                    to={{
                                        pathname: "/Home",
                                        state: {name: company},
                                        }} 
                                    state={[company.id, company.name]}>
                                    {company.name}
                                    </Nav.Link>
                                </Col>
                                )
                            })
                        }</Row>
                    </Col>
                    <Col></Col>
                </Row>

            </Container>
        </>
    );
}
import * as React from 'react';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import '../theme/Styles.css';
import { DataStore, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { updateGroupAndAdminStatus, checkAdminGroup } from '../components/Common/CheckGroup';


export const CreateNavigationBar = ({data}) => {

    const navigate = useNavigate();
    const [ username, setUsername ] = React.useState("")
    const [ infos, setInfos ] = React.useState({
        group: null,
    })

    const Exit = async() => {
        try {
            await DataStore.clear();
            navigate('/');
            data();
        }
        catch (error) {
            console.log('error signing out: ', error);
        }
    }

    React.useEffect(() => {
        updateGroupAndAdminStatus(setInfos);
    }, [])

    Auth.currentUserInfo().then(info => setUsername(info.username))

    return(
        <Navbar sticky="top">
            <Container >
                <Navbar.Brand href="/">ideal ISG</Navbar.Brand>
                <Nav className="me-auto" >
                    {/* <NavDropdown title="Eğitim" id="nav-dropdown">
                        <NavDropdown.Item href="/Egitim-Kayitlari" disabled>Eğitim Kayıtları</NavDropdown.Item>
                        <NavDropdown.Item href="/Egitim-Kaydi-Sorgulama" disabled>Eğitim Kaydı Sorgulama</NavDropdown.Item>
                        <NavDropdown.Item href="/Uzaktan-Egitim" disabled>Uzaktan Eğitim</NavDropdown.Item>
                    </NavDropdown> */}
                    {/* <NavDropdown title="İşyeri" id="nav-dropdown">
                        <NavDropdown.Item href="/Risk-Analizi" disabled>Risk Analizi</NavDropdown.Item>
                        <NavDropdown.Item href="/isyeriIslemleri">İşyeri İşlemleri</NavDropdown.Item>
                        <NavDropdown.Item href="/Dokuman-Takibi" disabled>Doküman Takibi</NavDropdown.Item>
                        <NavDropdown.Item href="/Duzenleyici-Onleyici-Faaliyetler" disabled>Düzenleyici Önleyici Faaliyetler</NavDropdown.Item>
                        <NavDropdown.Item href="/Maden-Verisi-Kayit" disabled>Maden Verisi Kayıt</NavDropdown.Item>
                        <NavDropdown.Item href="/Maden-Verisi-Sorgula" disabled>Maden Verisi Sorgula</NavDropdown.Item>
                        <NavDropdown.Item href="/Dogrulama-Servisleri" disabled>Doğrulama Servisleri</NavDropdown.Item>
                    </NavDropdown> */}
                    {
                    checkAdminGroup(infos.group) && (
                        <Nav.Link href="/CalisanBilgisiKayit" className='navlinks'>Kullanıcı Kaydı</Nav.Link>
                    ) }
                    
                    {/* <NavDropdown title="Çalışan" id="nav-dropdown">
                        <NavDropdown.Item href="/CalisanBilgisiKayit">Çalışan Kaydı</NavDropdown.Item>
                    </NavDropdown> */}
                    {/* <NavDropdown title="Ayarlar" id="nav-dropdown">
                        <NavDropdown.Item href="/Hesabim">Hesabım</NavDropdown.Item>
                    </NavDropdown> */}
                    {/* <Nav.Link href="/Rapor-İstatistik" disabled className='navlinks-disabled'>Rapor / İstatistik</Nav.Link> */}
                    <Nav.Link href="/ISG-Kutuphanesi" className='navlinks'>İSG Kütüphanesi</Nav.Link>
                    {/* <Nav.Link href="/ISG-Kutuphanesi" className='navlinks'>İdeal Kütüphanesi</Nav.Link> */}
                </Nav>
                <Nav>
                    <Nav.Link disabled className='navlinks-disabled'>{username}</Nav.Link>
                </Nav>
                
                <Button variant="primary" onClick={() => Exit()}>Çıkış yap</Button>      
            </Container>
        </Navbar> 
    )
}

import React from "react";
import { Modal, Button, Form, Row, Col, Dropdown, ButtonGroup } from "react-bootstrap";
import { DataStore, Storage } from "aws-amplify";
import { ModelISGKutuphane } from "../../models";

export const AddNewRecord = (show, setShow) => {

    const [ file, setFile ] = React.useState(null)

    const options = {
        "Mevzuatlar" : [
            "Yasalar",
            "Tüzükler",
            "Yönetmelikler",
            "Tebliğler",
        ],
        "Sağlık" : [
            "Sağlık Rehberleri"
        ],
        "Sektörel" : [
            "Deri Sektörü",
            "Gıda Sektörü",
            "İnşaat Sektörü",
            "Kimya Sektörü",
            "Maden Sektörü",
            "Metal Sektörü",
            "Mobilya Sektörü",
            "Tekstil Sektörü",
        ]
    }
    const [ title, setTitle ] = React.useState(null);
    const [ subtitle, setSubTitle ] = React.useState(null);
    const [ LinkCheck, setLinkCheck ] = React.useState(false)

    const UploadFile = async(baslik) => {
        if (file!==null || file!==undefined)
            await Storage.put(`ISG_Library/${title}/${subtitle}/${baslik}/${file.name}`, file, {
                level: "public",
                //contentType: "xlsx"
            });
    }

    const CreateRecord = async(values) => {
        if (LinkCheck===true)
            await DataStore.save(
                new ModelISGKutuphane({
                    Konu_Basligi: title,
                    Alt_Konu_Basligi: subtitle,
                    Baslik: values[2],
                    Kanunlar: values[3],
                    Maddeler: values[4],
                    ResmiGazeteTarihi: values[5],
                    Sayisi: parseInt(values[6]),
                    Link: values[values.length-1]
                }));
        else
            if(file!==null || file!==undefined)
                await DataStore.save(
                    new ModelISGKutuphane({
                        Konu_Basligi: title,
                        Alt_Konu_Basligi: subtitle,
                        Baslik: values[2],
                        Kanunlar: values[3],
                        Maddeler: values[4],
                        ResmiGazeteTarihi: values[5],
                        Sayisi: parseInt(values[6]),
                        Dokuman: file.name
                    }));
            else
                await DataStore.save(
                    new ModelISGKutuphane({
                        Konu_Basligi: title,
                        Alt_Konu_Basligi: subtitle,
                        Baslik: values[2],
                        Kanunlar: values[3],
                        Maddeler: values[4],
                        ResmiGazeteTarihi: values[5],
                        Sayisi: parseInt(values[6]),
                    }));
    }

    const sets = () => {  
        setShow(false)
        setTitle(null)
        setSubTitle(null)
    }
    
    async function submitValues (values) {
        values.preventDefault();
        const valuesList = [title, subtitle]

        for(let i=0;i<(values.target).length-1;i++){
            valuesList.push(values.target[i].value);
        }
        const hasEmptyValue = valuesList.some((value) => value === null || value.trim() === '');
        if (hasEmptyValue) {
            console.log("değerler boş olmamalı")
        }
        else{
            UploadFile(valuesList[2])
            CreateRecord(valuesList)
            sets()            
        }

        console.log(valuesList[2])
        console.log(valuesList)


        }

    return(
        <Modal show={show} onHide={()=> sets()} >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Dropdown as={ButtonGroup}>
                        <Button variant="success" disabled>{title == null ? (<>Konu Başlığı</>) :(<>{title}</>)}</Button>
                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            {(Object.keys(options)).map((t) => { return <Dropdown.Item key={t} onClick={() => {setTitle(t); setSubTitle(null)}}>{t}</Dropdown.Item>})}
                        </Dropdown.Menu>
                    </Dropdown>
                </Row>
                &ensp;
                <Row>
                    <Dropdown as={ButtonGroup} hidden={title == null}>
                        <Button variant="success" disabled>{subtitle == null ? (<>Alt Konu Başlığı</>) :(<>{subtitle}</>)}</Button>
                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            {title!== null && (options[title].map((t) => { return <Dropdown.Item key={t} onClick={() => {setSubTitle(t)}}>{t}</Dropdown.Item>}))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Row>
                &ensp;
            <Form onSubmit={submitValues}>
                    
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="Başlık">
                            <Form.Label>Başlık</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="Kanunlar">
                            <Form.Label>Kanunlar</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="Maddeler">
                            <Form.Label>Maddeler</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="Tarih">
                            <Form.Label>Resmi Gazete Tarihi</Form.Label>
                            <Form.Control autoComplete="off"  type="date"/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="Sayı">
                            <Form.Label>Sayısı</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>
                    </Row>

                    <Row>
                        {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="Döküman"
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                                checked={!LinkCheck}
                                onChange={() => setLinkCheck(false)}
                            />
                            <Form.Check
                                inline
                                label="Link"
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                                checked={LinkCheck}
                                onChange={() => setLinkCheck(true)}
                            />
                        </div>
                    ))}
                    </Row>
                    
                    <Row className="mb-3">
                        {LinkCheck ? (
                            <Form.Group as={Col} controlId="Döküman">
                                <Form.Label>Link</Form.Label>
                                <Form.Control autoComplete="off"  onChange={(e) => setFile(null)}/>
                            </Form.Group>
                        ) : (
                        <Form.Group as={Col} controlId="Döküman">
                            <Form.Label>Döküman</Form.Label>
                            <Form.Control autoComplete="off"  type="file" onChange={(e) => setFile(e.target.files[0])}/>
                        </Form.Group>
                        )}
                    </Row>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Button className='mb-4' variant="primary" type="submit">Ekle</Button>
                    </Form.Group>

                </Form>
            </Modal.Body>
        </Modal>
    )
}
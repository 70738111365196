import { updatePost } from '../../api/Model_Update';
import { Model_FormSend } from '../../api/Model_Create';

export const Send = async(infos, values) => {
    
    const valuesList = []
    for(let i=0;i<(values.target).length-1;i++){
        valuesList.push(values.target[i].value);
    } 

    var time = new Date()
    var timeID = time.getFullYear()+"-"+time.getMonth()+"-"+time.getDate()+"-"+time.getHours()+":"+time.getMinutes()+":"+time.getSeconds()

    updatePost(infos["subID"], infos["identityID"]);

    // const valuesList = []
    // for(let i=0;i<(values.target).length-1;i++){
    //     valuesList.push(values.target[i].value);
    // } 

    Model_FormSend(infos, valuesList, timeID)
}
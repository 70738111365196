import { Modal } from 'react-bootstrap';
import { CreateDownloadFilesButton } from './Button_DownloadFiles';


const Modal_Form = (infos, setInfos) => {

    return(
        <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
                <Modal.Header closeButton>
                <Modal.Title>Detaylar</Modal.Title>
                </Modal.Header>
                    <Modal.Body><span className="Bold">Seri No:</span> {infos.cardInfo.serialNo}</Modal.Body>
                    <Modal.Body><span className="Bold">Tarih:</span> {infos.cardInfo.createdDate}</Modal.Body>

                <Modal.Footer>
                {CreateDownloadFilesButton(infos, setInfos)}
                </Modal.Footer>
            </Modal>}
        </>
    )
}

// const Modal_ADT = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//                 <Modal.Header closeButton>
//                 <Modal.Title>Detaylar</Modal.Title>
//                 </Modal.Header>
//                     <Modal.Body><span className="Bold">İşyeri Unvanı:</span> {infos.firmInfo[1]}</Modal.Body>
//                     <Modal.Body><span className="Bold">İşyeri Adresi:</span> {infos.cardInfo["Isyeri_Adres"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikat Tarihi:</span> {infos.cardInfo["Tatbikat_Tarih"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikat Süresi:</span> {infos.cardInfo["Tatbikat_Sure"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikat Amacı:</span> {infos.cardInfo["Tatbikat_Amac"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikat Konuları:</span> {infos.cardInfo["Tatbikat_Konu"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikatta Görülen infoksiklik ve Aksaklıklar:</span> {infos.cardInfo["Tatbikat_Eksiklik"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikat Sonunda Elde Edilen Yararlar:</span> {infos.cardInfo["Tatbikat_Yarar"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Öneriler:</span> {infos.cardInfo["Tatbikat_Oneri"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Başarı Durumu:</span> {infos.cardInfo["Tatbikat_BasariDurumu"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Sonuç:</span> {infos.cardInfo["Tatbikat_Sonuc"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">Tatbikatı Yaptıran:</span> {infos.cardInfo["Tatbikat_Yaptiran"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">İşyeri Hekimi:</span> {infos.cardInfo["Isyeri_Hekimi"]}</Modal.Body>
//                     <Modal.Body><span className="Bold">İşyeri Yetkilisi:</span> {infos.cardInfo["Isyeri_Yetkilisi"]}</Modal.Body>
//                 <Modal.Footer>
//                 {CreateDownloadFilesButton("ADT", infos, setInfos)}
//                 </Modal.Footer>
//             </Modal>}
//         </>
//     )
// }

// const Modal_ATC = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//         <Modal.Header closeButton>
//         <Modal.Title>Detaylar</Modal.Title>
//         </Modal.Header>
//             <Modal.Body><span className="Bold">Tarih:</span> {infos.cardInfo["Tarih"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Öneriyi Veren:</span> {infos.cardInfo["Oneriyi_Veren"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Mevcut Durum:</span> {infos.cardInfo["Mevcut_Durum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Aksiyon:</span> {infos.cardInfo["Aksiyon"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Sorumlu:</span> {infos.cardInfo["Sorumlu"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Bitiş Tarihi:</span> {infos.cardInfo["Bitis_Tarihi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Sonuç:</span> {infos.cardInfo["Sonuc"]}</Modal.Body>
//         <Modal.Footer>
//         {CreateDownloadFilesButton("ATC", infos, setInfos)}
//         </Modal.Footer>
//     </Modal>}
//         </>
//     )
// }

// const Modal_BKM = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//         <Modal.Header closeButton>
//         <Modal.Title>Detaylar</Modal.Title>
//         </Modal.Header>
//             <Modal.Body><span className="Bold">Firma Adresi: </span>{infos.cardInfo["Firma_Adres"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Bulunduğu Yer: </span>{infos.cardInfo["Firma_Yer"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Marka: </span>{infos.cardInfo["Kompresor_Marka"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Model: </span>{infos.cardInfo["Kompresor_Model"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Basınç (Bar): </span>{infos.cardInfo["Kompresor_Basinc"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Hacim (Litre): </span>{infos.cardInfo["Kompresor_Hacim"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Yıl: </span>{infos.cardInfo["Kompresor_Yil"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Seri No: </span>{infos.cardInfo["Kompresor_Seri_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Marka: </span>{infos.cardInfo["Motor_Mark"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Model: </span>{infos.cardInfo["Motor_Model"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Güç (KW): </span>{infos.cardInfo["Motor_Guc"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Gerilim (V): </span>{infos.cardInfo["Motor_Gerilim"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Devir (dv/dk): </span>{infos.cardInfo["Motor_Devir"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Yıl: </span>{infos.cardInfo["Motor_Yil"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Diploma Tarihi - Diploma No: </span>{infos.cardInfo["Diploma_Tarih_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Ekipnet Kayıt No: </span>{infos.cardInfo["Ekipnet_Kayit_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Kontrol Tarihi: </span>{infos.cardInfo["Kontrol_Tarihi"]}</Modal.Body>
//         <Modal.Footer>
//         {CreateDownloadFilesButton("BKM", infos, setInfos)}
//         </Modal.Footer>
//     </Modal>}
//         </>
//     )
// }

// const Modal_F = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//         <Modal.Header closeButton>
//         <Modal.Title>Detaylar</Modal.Title>
//         </Modal.Header>
//             <Modal.Body><span className="Bold">Markası:</span> {infos.cardInfo["Marka"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Firma No:</span> {infos.cardInfo["Firma_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Bulunduğu Yer:</span> {infos.cardInfo["Yer"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Model (yıl):</span> {infos.cardInfo["Model"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Tipi:</span> {infos.cardInfo["Tip"]}</Modal.Body>
//             <Modal.Body><span className="Bold">E/D/LPG:</span> {infos.cardInfo["E_D_LPG"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Seri No:</span> {infos.cardInfo["Seri_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Kapasite (kg):</span> {infos.cardInfo["Kapasite"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Kal.Yük (mm):</span> {infos.cardInfo["Kal_Yuk"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Test Ağ. (kg):</span> {infos.cardInfo["Test_Agirlik"]}</Modal.Body>
//             <Modal.Body><span className="Bold">T.Yüksek (mm):</span> {infos.cardInfo["T_Yuksek"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Ağ.Mrk (mm):</span> {infos.cardInfo["Ag_Mrk"]}</Modal.Body>
//         <Modal.Footer>
//         {CreateDownloadFilesButton("FF", infos, setInfos)}
//         </Modal.Footer>
//     </Modal>}
//         </>
//     )
// }

// const Modal_RD = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//         <Modal.Header closeButton>
//         <Modal.Title>Detaylar</Modal.Title>
//         </Modal.Header>
//             <Modal.Body><span className="Bold">Firma Adı:</span> {infos.cardInfo["Firma_Ad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Firma Adresi:</span> {infos.cardInfo["Firma_Adres"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşveren Adı-Soyadı:</span> {infos.cardInfo["Isveren_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşyeri NACE Kodu:</span> {infos.cardInfo["Isyeri_NACE_Kod"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Sektör-Tehlike Sınıfı:</span> {infos.cardInfo["Sektor_Tehlike_Sinifi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Çalışan Sayısı:</span> {infos.cardInfo["Calisan_Sayisi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşveren/Vekili Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Isveren_Vekili_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşveren/Vekili Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Isveren_Vekili_Bolum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İş Güvenliği Uzmanı Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Is_Guvenligi_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İş Güvenliği Uzmanı Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Is_Guvenligi_Bolum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşyeri Hekimi Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Isyeri_Hekimi_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşyeri Hekimi Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Isyeri_Hekimi_Bolum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Çalışan Temsilcisi Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Temsilci_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Çalışan Temsilcisi Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Temsilci_Bolu"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Destek Elemanı Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Destek_Ad_Soyad"]}</Modal.Body>
//             {/* <Modal.Body><span className="Bold">Çalışan Temsilcisi Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Temsilci_Bolu"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Destek Elemanı Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Destek_Ad_Soyad"]}</Modal.Body> */}
//             <Modal.Body><span className="Bold">Destek Elemanı Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Destek_Bolum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Görüş İstenilen Kişiler Ad-Soyad:</span> {infos.cardInfo["RDEkibi_Gorus_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Görüş İstenilen Kişiler Mesleği-Çalıştığı Bölüm:</span> {infos.cardInfo["RDEkibi_Gorus_Bolum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Risk Değerlendirme Tarihi:</span> {infos.cardInfo["RD_Tarihi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Risk Değerlendirmenin Geçerlilik Tarihi:</span> {infos.cardInfo["RD_Gecerlilik_Tarihi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Uygulanan Risk Değerlendirme Metodu:</span> {infos.cardInfo["RD_Metod"]}</Modal.Body>
//         <Modal.Footer>
//         {CreateDownloadFilesButton("RD", infos, setInfos)}
//         </Modal.Footer>
//     </Modal>}
//         </>
//     )
// }

// const Modal_TIE = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//         <Modal.Header closeButton>
//         <Modal.Title>Detaylar</Modal.Title>
//         </Modal.Header>
//             <Modal.Body><span className="Bold">Ad-Soyad:</span> {infos.cardInfo["Ad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Tarih:</span> {infos.cardInfo["Tarih"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İşyeri Adı/Bölümü:</span> {infos.cardInfo["Isyeri_Ad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">Puanı:</span> {infos.cardInfo["Puan"]}</Modal.Body>
//         <Modal.Footer>
//         {CreateDownloadFilesButton("TIE", infos, setInfos)}
//         </Modal.Footer>
//     </Modal>}
//         </>
//     )
// }

// const Modal_TT = (infos, setInfos) => {
//     return(
//         <>{infos.cardInfo && <Modal show={infos.show} onHide={() => setInfos((prevState) => ({...prevState, show:false}))}>
//         <Modal.Header closeButton>
//         <Modal.Title>Detaylar</Modal.Title>
//         </Modal.Header>
//             <Modal.Body><span className="Bold">ÖLÇÜMÜ TALEP EDEN KURUM:</span> {infos.cardInfo["A_Talep_Eden_Kurum_Ad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">KURUM ADRESİ:</span> {infos.cardInfo["A_Kurum_Adres"]}</Modal.Body>
//             <Modal.Body><span className="Bold">TEL:</span> {infos.cardInfo["A_Tel"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İLGİLİ KİŞİ:</span> {infos.cardInfo["A_Ilgili_Kisi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ÖLÇÜM YAPILAN YERİN ADRESİ:</span> {infos.cardInfo["A_Olcum_Yapilan_Yer_Adres"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ÖLÇÜM GÜNÜ:</span> {infos.cardInfo["A_Olcum_Gunu"]}</Modal.Body>
//             <Modal.Body><span className="Bold">PROJE ONAY TARİHİ VE SAYISI:</span> {infos.cardInfo["A_Proje_Onay_Tarihi_Sayisi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">HAVA DURUMU:</span> {infos.cardInfo["A_Hava_Durumu"]}</Modal.Body>
//             <Modal.Body><span className="Bold">TOPRAK DURUMU:</span> {infos.cardInfo["A_Toprak_Durumu"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ŞEBEKE TİPİ:</span> {infos.cardInfo["A_Sebeke_Tipi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ŞEBEKE GERİLİMİ:</span> {infos.cardInfo["A_Sebeke_Gerilimi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">KONTROL NEDENİ:</span> {infos.cardInfo["A_Kontrol_Nedeni"]}</Modal.Body>
//             <Modal.Body><span className="Bold">TESİSİN KULLANMA AMACI:</span> {infos.cardInfo["B_Amac"]}</Modal.Body>
//             <Modal.Body><span className="Bold">YAPI CİNSİ:</span> {infos.cardInfo["B_Yapi_Cinsi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">TOPRAKLAYICI TESİS ŞEKLİ:</span> {infos.cardInfo["B_Tesis_Sekli"]}</Modal.Body>
//             <Modal.Body><span className="Bold">EŞPOTANSİYEL BARA:</span> {infos.cardInfo["B_Bara"]}</Modal.Body>
//             <Modal.Body><span className="Bold">MARKA MODEL:</span> {infos.cardInfo["C_Olcum_Cihazi_Marka_Model"]}</Modal.Body>
//             <Modal.Body><span className="Bold">SERİ NO:</span> {infos.cardInfo["C_Olcum_Cihazi_Seri_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">HATA SINIFI:</span> {infos.cardInfo["C_Olcum_Cihazi_Hata_Sinifi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ÖLÇÜM YÖNTEMİ:</span> {infos.cardInfo["C_Olcum_Yontemi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">KALİBRASYON YAPAN KURUM:</span> {infos.cardInfo["C_Olcum_Cihazi_Kalibrasyon_Kurum"]}</Modal.Body>
//             <Modal.Body><span className="Bold">KALİBRASYON ONAY TARİHİ VE SAYISI:</span> {infos.cardInfo["C_Olcum_Cihazi_Kalibrasyon_Tarih_Sayi"]}</Modal.Body>
//             <Modal.Body><span className="Bold">GEÇERLİLİK SÜRESİ:</span> {infos.cardInfo["C_Olcum_Cihazi_Kalibrasyon_Sure"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ADI SOYADI:</span> {infos.cardInfo["C_Olcum_Yapan_Ad_Soyad"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ÜNVANI:</span> {infos.cardInfo["C_Olcum_Yapan_Unvan"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ODA SİCİL NO:</span> {infos.cardInfo["C_Olcum_Yapan_Oda_Sicil_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">ODA YETKİ BELGE NO:</span> {infos.cardInfo["C_Olcum_Yapan_Oda_Yetki_Belge_No"]}</Modal.Body>
//             <Modal.Body><span className="Bold">SONUÇ VE ÖNERİLER:</span> {infos.cardInfo["E_Sonuc"]}</Modal.Body>
//             <Modal.Body><span className="Bold">İLGİLİ YASA VE YÖNETMELİKLER:</span> {infos.cardInfo["F_Yasa_Yonetmelik"]}</Modal.Body>
//         <Modal.Footer>
//         {CreateDownloadFilesButton("TT", infos, setInfos)}
//         </Modal.Footer>
//     </Modal>}
//         </>
//     )
// }

export {
    Modal_Form
}
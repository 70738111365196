import { Button, Table, Collapse, Row } from "react-bootstrap";
import * as React from "react";
import { EditFirmInfo } from "./Modal_EditFirm";

export const ExpandableRowTable = (model, setModel) => {
    const [ open, setOpen] = React.useState({});
    const [ show, setShow ] = React.useState(false);
    const [ info, setInfo ] = React.useState(null);

    React.useEffect(() => {
        if (model != null)
            model.map((key) => {
                setOpen(prevState => ({...prevState, [key["FabrikaAdi"]]: false}))
            })
            console.log(model)
        }, [model])

    const Edit = (i) => {
        setInfo(i)
        console.log(i)
        setShow(true)
    }

    return(
        <>
            {info&&EditFirmInfo(info, show, setShow, setModel, model)}
            <Table striped bordered hover size="sm" style={{fontSize:13}}>
                <thead>
                    <tr>
                        <th></th>
                        <th>İşyeri Adı</th>
                        <th>SGK Sicil No</th>
                        <th>Aktif Çalışan Sayısı</th>
                    </tr>
                </thead>
                <tbody>
                    
                {model && model.map((key) => {
                    return(
                        <>
                            <tr>
                                <td onClick={() => setOpen(prevState => ({...prevState, [key["FabrikaAdi"]]: !open[key["FabrikaAdi"]]}))}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}></td>
                                <td>{key["FabrikaAdi"]}</td>
                                <td>{key["SGKsicilno"]}</td>
                                <td>{key["AktifCalisanSayisi"]}</td>
                            </tr>
                            <tr>
                                {open&&
                                    <Collapse in={open[key["FabrikaAdi"]]}>
                                        <td colSpan={4}>
                                            <div>
                                            <Row className="w-auto p-3">
                                                <p>İşyeri Yetkilileri</p>
                                                <p>-Henüz işyeri yetkilisi eklenmemiş-</p>
                                            </Row>
                                            <Row className="w-auto p-3">
                                                <p>İşyeri Kullanıcısı</p>
                                                <p>-Henüz işyeri kullanıcısı eklenmemiş-</p>
                                            </Row>
                                            <Button onClick={() => {Edit(key)}}>Düzenle</Button>
                                            <Button>Sil</Button>
                                            </div>
                                        </td>
                                    </Collapse>
                                }
                                
                            </tr>
                                
                        </>
                    )}
                )}
                    
                </tbody>
            </Table>
        </>
    )
}
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Auth, DataStore } from 'aws-amplify';

const CreateUser = () => {

    const [show, setShow] = React.useState(false);

    const handleClose = () => {setShow(false)}
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const [ group, setGroup ] = React.useState(null)
    const [ message, setMessage ] = React.useState('')
    const [ step, setStep ] = React.useState(0)

    const signUp = async(username, surname, password, email, group, TC, diplomaNo) => {
        try{
            await Auth.signUp({
                username, 
                password, 
                attributes: { email, 
                    'custom:group': group,
                    'custom:CalismaDurumu': "1",
                    'custom:TCKimlikNo': TC,
                    'custom:DiplomaBilgisi': diplomaNo,
                    'custom:Surname': surname,
                 }
            })
            console.log('success')
            setStep(1)
        } catch(error) {
            setMessage("Kayıt oluşurken hata oluştu!")
            handleShow()
            console.log('error signing up: ', error.message)
        }
    }

    const confirmSignUp = async(username, authenticationCode) => {
        try {
            await Auth.confirmSignUp(username, authenticationCode)
            await DataStore.clear();
            await DataStore.start();
            console.log('success')
        } catch (error) {
            setMessage("Kayıt doğrulanmadı!")
            handleShow()
            console.log('error confirming sign up: ', error)
        }
    }

    async function submitValues1 (values) {
        values.preventDefault();
        const valuesList = []
        for(let i=0;i<(values.target).length-1;i++){
            valuesList.push(values.target[i].value);
        }
        console.log(valuesList)
        const hasEmptyValue = valuesList.some((value) => value.trim() === '');
        if (hasEmptyValue) {
            setMessage("Bilgiler boş olmamalı")
            handleShow()
        }
        else{
            signUp(valuesList[0],valuesList[1],valuesList[2], valuesList[3], group, valuesList[4], valuesList[5])
        }
        
    };

    async function submitValues2 (values) {
        values.preventDefault();
        const valuesList = []
        for(let i=0;i<(values.target).length-1;i++){
            valuesList.push(values.target[i].value);
        }
        confirmSignUp(valuesList[0],valuesList[1])
    };

    return (
        <>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Hata!</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Kapat
            </Button>
            </Modal.Footer>
        </Modal>

        <Container fluid className='container-custom'>
            <Row className='heighting'>
                { <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col> }
                <Col sm={7} className='main-col-custom mt-3'>

                { step === 0 && (
                    <Form onSubmit={submitValues1}>

                        <Form.Group as={Col} controlId="user name">
                            <Form.Label>İsim</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="user surname">
                            <Form.Label>Soyad</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>  
                            
                        <Form.Group as={Col} controlId="user pasword">
                            <Form.Label>Şifre</Form.Label>
                            <Form.Control type="password" />
                        </Form.Group>         

                        <Form.Group as={Col} controlId="user mail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="user id">
                            <Form.Label>TC Kimlik No</Form.Label>
                            <Form.Control autoComplete="off"  type="number"/>
                        </Form.Group>

                        {/* <Form.Group as={Col} controlId="user info">
                            <Form.Label>Sertifika Diploma Bilgisi</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group> */}

                        &ensp;
                        <Row>
                            <Col>
                                <Dropdown as={ButtonGroup}>
                                    <Button style={{width:100}}variant="success" disabled>{group == null ? (<>Erişim Yetki</>) : (<>{group}</>)}</Button>
                                    <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={()=> setGroup("Admin")}>Admin</Dropdown.Item>
                                        <Dropdown.Item onClick={()=> setGroup("ISG_çalışan")}>ISG çalışanı</Dropdown.Item>
                                        <Dropdown.Item onClick={()=> setGroup("İşveren")}>İşveren</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        
                        &ensp;
                        <Row>
                            <Col>
                                <Button className='mb-4' variant="primary" type="submit">Gönder</Button>
                            </Col>                                  
                        </Row>
                    </Form> )}

                { step === 1 && (
                    <Form onSubmit={submitValues2}>

                        <Form.Group as={Col} controlId="deneme 6">
                            <Form.Label>Kullanıcı İsmi</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="deneme 7">
                            <Form.Label>Onay Kodu</Form.Label>
                            <Form.Control autoComplete="off" />
                        </Form.Group>  

                        &ensp;
                        <Row>
                            <Col>
                                <Button className='mb-4' variant="primary" type="submit">Gönder</Button>
                            </Col>                                  
                        </Row>
                    </Form> )}      
                </Col>
                <Col></Col>
            </Row>
        </Container>
        </>
    )
}

export default CreateUser;
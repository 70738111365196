import { CreateRoutes } from "./Navigation_Routes";
import { CreateNavigationBar } from "./Navigation_Navbar";
import { BrowserRouter as Router }   from "react-router-dom";

export const Navigations = ({data}) => {
    return(
        <>
        <Router>
            <CreateNavigationBar data={data}/>
            {CreateRoutes()}
        </Router>
        </> 
    )
}
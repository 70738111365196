import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";

export const ListCardsSecondLevel = () => {

    let location = useLocation();

    const navigate = useNavigate()

    const [ infos, setInfos ] = React.useState({
        isAdmin: false,
        Firm: {
            id  : location.state[0],
            name : location.state[1]
        },
        cards : location.state[2],
        level1: location.state[3],
        level2: location.state[2].id
    })

    return(
        <>
            <Container fluid className='container-custom'>
                <Row className='heighting'>
                    <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col>
                    
                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>
                                <div className="firm-header">{infos.Firm.name}</div>
                                <hr/>
                                <div className="firm-header2">{location.state[5]}</div>
                                <hr/>
                                <div className="firm-header3">{infos.cards.name}</div>
                                <hr/>
                    {infos.cards.subcards.map((card) => {        
                            return(
                            <Col className="container-cards" key={"Col-"+card.id}>
                                <Card key={card.id} className="Cards">
                                    <Card.Body>
                                        <Card.Title>{card.name}</Card.Title>
                                        <Card.Text>
                                        {card.description}
                                        </Card.Text>
                                        <Button as={Link}
                                            to={card.link} 
                                            state={[location.state[0],location.state[1], card, infos.level1, infos.level2, location.state[5], infos.cards.name]}
                                            variant="primary">Detaylar
                                        </Button>
                                    
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                        
                    })}
                    </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </>
    )
}
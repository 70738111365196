import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../theme/Styles.css';
import { Routes, Route }                            from "react-router-dom";
import { ListForms }                                from '../pages/Forms/Forms Home Page';
import { ListCompany }                              from '../pages/Company/List';
import { CreateCompany }                            from '../pages/Company/Create';
import FirmChoicePage                               from '../pages/Page_Main';

import { ListCardsSecondLevel }                     from '../pages/Forms/ListCardsSecondLevel';
import { ListCardsFirstLevel }                      from '../pages/Forms/ListCardsFirstLevel';

import { ListUser }                                 from '../pages/Navbar Pages/Çalışan/Çalışan Kaydı/List';
import { Workplace }                                from '../pages/Navbar Pages/İşyeri/İşyeri İşlemleri';

import CreateUser                                   from '../pages/Navbar Pages/Çalışan/Çalışan Kaydı/Create';
import { CreateForm }                               from '../pages/Forms/Create';
import { ISGLibrary }                              from '../pages/Navbar Pages/ISG Kütüphanesi/Main';
import { ListCards } from '../pages/Forms/List';
export const CreateRoutes = () => {

    return(
            <Routes>
                <Route exact path="/"                               element={<FirmChoicePage />}/>
                <Route exact path="/Home"                           element={<ListForms />} />
                <Route exact path="/Yeni-Fabrika-Ekle"              element={<CreateCompany />} />
                <Route exact path="/Firmalar"                       element={<ListCompany/>} />
                <Route exact path="/Yeni-Kullanici-Ekle"            element={<CreateUser />} />

                <Route exact path="/Yeni-Form-Yukle"                element={<CreateForm />} />            

                <Route exact path="/Calisma-Planlari"                           element={<ListCardsFirstLevel />} />
                    <Route exact path="/Yillik-Calisma-Plani"                               element={<ListCards />} />
                    <Route exact path="/Yillik-Egitim-Plani"                                element={<ListCards />} />
                    <Route exact path="/Yil-Sonu-Degerlendirme-Raporu"                      element={<ListCards />} />
                <Route exact path="/Atama-Yazilari"                             element={<ListCardsFirstLevel />} />
                    <Route exact path="/Isveren-Vekili-atama-yazisi"                        element={<ListCards />} />
                    <Route exact path="/ISG-Katip-Atamalari"                                element={<ListCardsSecondLevel />} />
                        <Route exact path="/Isyeri-hekimi-atamasi"                                  element={<ListCards />} />
                        <Route exact path="/Is-guvenligi-uzmani-atamasi"                            element={<ListCards />} />
                        <Route exact path="/Diger-saglik-personeli-atamasi"                         element={<ListCards />} />
                    <Route exact path="/Calisan-Temsilcisi"                                 element={<ListCardsSecondLevel />} />
                        <Route exact path="/Calisan-temsilcisi-secim-duyurusu"                      element={<ListCards />} />
                        <Route exact path="/Secim-sonucları"                                        element={<ListCards />} />
                        <Route exact path="/Calisan-temsilcisi-atamasi"                             element={<ListCards />} />
                        <Route exact path="/Calisan-temsilcisi-egitimi"                             element={<ListCards />} />
                    <Route exact path="/Destek-Elemani-Atamasi"                             element={<ListCards />} />
                <Route exact path="/Risk-Degerlendirme-Analizi"                 element={<ListCardsFirstLevel />} />
                    <Route exact path="/Risk-Degerlendirme-Ekibi-Atama-Yazisi"              element={<ListCards />} />
                    <Route exact path="/Risk-Degerlendirme-Ekibi-Egitimi"                   element={<ListCards />} />
                    <Route exact path="/Risk-degerlendirme-analizi-proseduru"               element={<ListCards />} />
                    <Route exact path="/Risk-degerlendirme-analizi"                         element={<ListCards />} />
                <Route exact path="/Acil-Durum-Eylem-Plani"                     element={<ListCardsFirstLevel />} />
                    <Route exact path="/Acil-durum-ekipleri-atama-yazisi"                   element={<ListCards />} />
                    <Route exact path="/Acil-durum-ekipleri-egitimi"                        element={<ListCards />} />
                    <Route exact path="/Acil-durum-eylem-plani"                             element={<ListCards />} />
                    <Route exact path="/Acil-durum-tatbikati"                               element={<ListCards />} />
                <Route exact path="/ISG-Kurulu"                                 element={<ListCardsFirstLevel />} />
                    <Route exact path="/Ic-Yonerge"                                         element={<ListCards />} />
                    <Route exact path="/ISG-Kurul-Proseduru"                                element={<ListCards />} />
                    <Route exact path="/Kurul-Uyeleri-Atama-yazisi"                         element={<ListCards />} />
                    <Route exact path="/Kurul-Uyeleri-Egitimi"                              element={<ListCards />} />
                    <Route exact path="/ISG-Kurul-Toplantisi-Cagri-Formu"                   element={<ListCards />} />
                    <Route exact path="/ISG-Kurul-Toplantisi-Katilim-Formu"                 element={<ListCards />} />
                    <Route exact path="/ISG-Kurul-Toplantisi-Karar-Formu"                   element={<ListCards />} />
                <Route exact path="/ISG-Egitimleri"                             element={<ListCardsFirstLevel />} />
                    <Route exact path="/Temel-ISG-Egitimleri"                               element={<ListCards />} />
                    <Route exact path="/ISG-Egitimi-Sorulari"                               element={<ListCards />} />
                    <Route exact path="/Ise-Baslama-Egitimleri"                             element={<ListCards />} />
                    <Route exact path="/Toolbox-Egitimleri"                                 element={<ListCards />} />
                <Route exact path="/Is-Kazasi-Ramakkala"                        element={<ListCardsFirstLevel />} />
                    <Route exact path="/Is-kazasi-tutanagi"                                 element={<ListCards />} />
                    <Route exact path="/Is-kazasi-SGK-Bildirim-formu"                       element={<ListCards />} />
                    <Route exact path="/Ramakkala-Olay-Tutanagi"                            element={<ListCards />} />
                    <Route exact path="/Is-Kazasi-sonrasi-egitim-formu"                     element={<ListCards />} />
                    <Route exact path="/Kaza-Kok-Neden-Analizi"                             element={<ListCards />} />
                <Route exact path="/Uygunsuzluk-Formlari"                       element={<ListCardsFirstLevel />} />
                    <Route exact path="/Duzeltici-ve-onleyici-tutanak-formu"                element={<ListCards />} />
                    <Route exact path="/Uyarı ve Bilgilendirme Formu"                       element={<ListCards />} />
                <Route exact path="/Takip-Kontrol-Formlari"                     element={<ListCardsFirstLevel />} />
                    <Route exact path="/Yangin-Tupleri-kontrol-formu"                       element={<ListCards />} />
                    <Route exact path="/Servis-Araclari-kontrol-formu"                      element={<ListCards />} />
                    <Route exact path="/Acil-cikis-kapilari-ve-ekipmanlari-kontrol-formu"   element={<ListCards />} />
                    <Route exact path="/Saha-kontrol-formu"                                 element={<ListCards />} />
                    <Route exact path="/Mutfak-Yemekhane-kontrol-Formu"                     element={<ListCards />} />
                    <Route exact path="/Revir-Kontrol-Formu"                                element={<ListCards />} />
                    <Route exact path="/Iskele-kontrol-Formu"                               element={<ListCards />} />
                    <Route exact path="/Is-Makinalari-kontrol-formu"                        element={<ListCards />} />
                    <Route exact path="/Is-Ekipmanlari-Kontrol-Formu"                       element={<ListCards />} />
                <Route exact path="/KKD-Formlari"                               element={<ListCardsFirstLevel />} />
                    <Route exact path="/KKD-standartlar-uygunluk-tablosu"                   element={<ListCards />} />
                    <Route exact path="/KKD-Zimmet-Formu"                                   element={<ListCards />} />
                    <Route exact path="/KKD-Matrisi"                                        element={<ListCards />} />
                <Route exact path="/Periyodik-Kontrol-Formlari"                 element={<ListCardsFirstLevel />} />
                    <Route exact path="/Elektrik-ic-Tesisati-periyodik-kontrol-Formu"       element={<ListCards />} />
                    <Route exact path="/Topraklama-tesisati-periyodik-kontrol-Formu"        element={<ListCards />} />
                    <Route exact path="/Jenerator-Periyodik-kontrol-Formu"                  element={<ListCards />} />
                    <Route exact path="/Kompresor-ve-Hava-tanklari-Periyodik-Kontrol-Formu" element={<ListCards />} />
                    <Route exact path="/Asansor-Periyodik-Kontrol-Formu"                    element={<ListCards />} />
                    <Route exact path="/Havalandirma-Periyodik-Kontrol-Formu"               element={<ListCards />} />
                    <Route exact path="/Yangin-Tesisati-Periyodik-Kontrol-Formu"            element={<ListCards />} />
                    <Route exact path="/Paratoner-Periyodik-Kontrol-Formu"                  element={<ListCards />} />
                    <Route exact path="/Kazan-Periyodik-Kontrol-Formu"                      element={<ListCards />} />
                    <Route exact path="/Kaldırma-Araclari-Periyodik-Kontrol-Formu"          element={<ListCards />} />
                    <Route exact path="/Revir-malzemeleri-kalibrasyon-olcumleri"            element={<ListCards />} />
                    <Route exact path="/Kullanim-ve-atik-suyu-analizleri"                   element={<ListCards />} />
                <Route exact path="/Is-Hijyeni-Olcumleri"                       element={<ListCardsFirstLevel />} />
                    <Route exact path="/Ortam-toz-ve-kisisel-maruziyet-olcumu"              element={<ListCards />} />
                    <Route exact path="/Ortam-gurultu-ve-kisisel-maruziyet-olcumu"          element={<ListCards />} />
                    <Route exact path="/Termal-Konfor-olcumu"                               element={<ListCards />} />
                    <Route exact path="/Aydinlatma-Olcumu"                                  element={<ListCards />} />
                    <Route exact path="/Elektromanyetik-Alan-Olcumu"                        element={<ListCards />} />
                <Route exact path="/Is-Izinleri"                                element={<ListCardsFirstLevel />} />
                    <Route exact path="/Genel-Calisma-is-izin-formu"                        element={<ListCards />} />
                    <Route exact path="/Sicak-Calisma-is-izin-formu"                        element={<ListCards />} />
                    <Route exact path="/Yuksekte-calisma-is-izin-formu"                     element={<ListCards />} />
                    <Route exact path="/Soguk-Calisma-is-izin-formu"                        element={<ListCards />} />
                    <Route exact path="/Kapali-alanda-calisma-is-izin-formu"                element={<ListCards />} />
                    <Route exact path="/Gece-Calismasi-is-izin-formu"                       element={<ListCards />} />
                    <Route exact path="/Elektrik-calismasi-is-izin-formu"                   element={<ListCards />} />
                <Route exact path="/Saglik"                                                 element={<ListCardsFirstLevel />} />
                    <Route exact path="/Ise-giris-muayene-formu"                            element={<ListCards />} />
                    <Route exact path="/Revir-ilac-kontrol-formu"                           element={<ListCards />} />
                    <Route exact path="/Gizlilik-esasli-Ise-Giris-Periyodik-Muayene-Bildirim-Formu"                     element={<ListCards />} />
                <Route exact path="/Talimatlar"                                 element={<ListCardsFirstLevel />} />
                    <Route exact path="/Genel-Is-Guvenligi-Talimati"                        element={<ListCards />} />
                    <Route exact path="/Elle-kaldirma-ve-tasima-talimati"                   element={<ListCards />} />
                    <Route exact path="/Yuksekte-calisma-talimati"                          element={<ListCards />} />
                    <Route exact path="/Elektrik-isleri-talimati"                           element={<ListCards />} />
                    <Route exact path="/Is-makinalari-talimati"                             element={<ListCards />} />
                    <Route exact path="/KKD-talimati"                                       element={<ListCards />} />
                    <Route exact path="/Vinc-Kullanma-talimati"                             element={<ListCards />} />
                    <Route exact path="/Jenerator-kullanma-talimati"                        element={<ListCards />} />
                    <Route exact path="/Basincli-tupler-kullanma-talimati"                  element={<ListCards />} />
                <Route exact path="/Diger-Formatlar"                            element={<ListCardsFirstLevel />} />
                    <Route exact path="/Mesleki-yeterlilik-belgesi"                         element={<ListCards />} />
                    <Route exact path="/Kimyasal-Maddelerin-GBF-belgeleri"                  element={<ListCards />} />
                    <Route exact path="/Hijyen-Belgesi"                                     element={<ListCards />} />
                    <Route exact path="/Yuksekte-calisma-belgesi"                           element={<ListCards />} />

                <Route exact path="/ISG-Kutuphanesi"                element={<ISGLibrary />} />
                <Route exact path="/CalisanBilgisiKayit"            element={<ListUser />}/>
                <Route exact path="/isyeriIslemleri"                element={<Workplace />}/>
            </Routes>
        );
}
import { Row, Col, Button, Container, Table} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "../../../components/Common/Table_SearchInput";
import { CreateLink } from "../../../components/Components_Page_TemelISGEğitimi/Download_Browse";
import { Model_ISGKutuphane } from "../../../api/Model_Read";
import * as React from "react";
import { AddNewRecord } from "../../../components/Components_Page_TemelISGEğitimi/Modal_ISGNewRecord";
import { updateGroupAndAdminStatus, checkAdminGroup } from "../../../components/Common/CheckGroup";

export const ISGLibrary = () => {

    const navigate = useNavigate();
    const [ model, setModel ] = React.useState(null);
    const [ subtitle, setSubTitle] = React.useState(null);
    const [ title, setTitle ] = React.useState(null);
    const [ show, setShow] = React.useState(false);
    const [ infos, setInfos ] = React.useState({
        group: null,
    })


    React.useEffect(()=>{
        updateGroupAndAdminStatus(setInfos);

        async function getModelData(){
            if(subtitle !== null){
                const data = await Model_ISGKutuphane(subtitle)
            setModel(data)
            }
        }
        getModelData()
        
     },[subtitle]);

    return(
        <>
        {AddNewRecord(show, setShow)}
        <Container fluid="true" className='container-custom'>
            <Row className='heighting'>
            <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col>

                <Col sm={7} fluid="true" className='main-col-custom mt-4'>
                    <Row className="firm-header">İSG Kütüphanesi</Row>
                    &ensp;
                    <Row style={{fontSize: 30}}>Konu Başlığı</Row>
                    &ensp;
                    {SearchInput(setSubTitle, setTitle)}
                    &ensp;

                    <Row style={{fontSize: 30}}>Dökümanlar</Row>
                    &ensp;
                    {checkAdminGroup(infos.group) &&
                    <Row>
                        <Col>
                            <Button onClick={()=> {setShow(true)}}>Yeni Kayıt Ekle</Button>
                        </Col>

                        <Col>
                            <Button disabled onClick={()=> {}}>Düzenle</Button>
                        </Col>

                        <Col>
                            <Button disabled onClick={()=> {}}>Sil</Button>
                        </Col>
                        
                    </Row>
                    }
                    &ensp;
                    <Table striped hover size="sm" style={{fontSize:13}}>
                        <thead>
                            <tr>
                                <th>Başlık</th>
                                <th>Kanunlar</th>
                                <th>Maddeler</th>
                                <th>Resmi Gazete Tarihi</th>
                                <th>Sayısı</th>
                                <th>Döküman</th>
                            </tr>
                        </thead>
                        <tbody>
                            {model && model.map((key,index) => {
                                return(
                                <tr key={index}>
                                    <td key={index+"-"+"Baslik-"+key["Baslik"]}>{key["Baslik"]}</td>
                                    <td key={index+"-"+"Kanunlar-"+key["Kanunlar"]}>{key["Kanunlar"]}</td>
                                    <td key={index+"-"+"Maddeler-"+key["Maddeler"]}>{key["Maddeler"]}</td>
                                    <td key={index+"-"+"ResmiGazeteTarihi-"+key["ResmiGazeteTarihi"]}>{key["ResmiGazeteTarihi"]}</td>
                                    <td key={index+"-"+"Sayisi-"+key["Sayisi"]}>{key["Sayisi"]}</td>

                                    {CreateLink(key, title, subtitle, index)}
                                    
                                </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        </>
    )
}
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, Col } from 'react-bootstrap';
import '../../theme/Styles.css';
import {useLocation, Link} from "react-router-dom";
import '../../theme/Styles.css';

export const CreateHomePageCards = () => {

    let location = useLocation();


    const Cards = [
        {
            id: 0,
            name: "Çalışma Planları",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Calisma-Planlari',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Yıllık Çalışma Planı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yillik-Calisma-Plani',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Yıllık Eğitim Planı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yillik-Egitim-Plani',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Yıl Sonu Değerlendirme Raporu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yil-Sonu-Degerlendirme-Raporu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 1,
            name: "Atama Yazıları",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Atama-Yazilari',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "İşveren Vekili atama yazısı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Isveren-Vekili-atama-yazisi',
                        level: 2,
                        subcards: []
                    },
                    ]
                },
                {
                    id: 1,
                    name: "İSG Katip Atamaları",
                    description: "Kart ile ilgili detaylar burada gözükecek.",
                    link: '/ISG-Katip-Atamalari',
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "İşyeri hekimi ataması",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Isyeri-hekimi-atamasi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "İş güvenliği Uzmanı ataması",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-guvenligi-Uzmani-atamasi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Diğer sağlık personeli ataması",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Diger-saglik-personeli-atamasi',
                        level: 2,
                        subcards: []
                    },
                    ]
                },
                {
                    id: 2,
                    name: "Çalışan temsilcisi",
                    description: "Kart ile ilgili detaylar burada gözükecek.",
                    link: '/Calisan-temsilcisi',
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Çalışan temsilcisi seçim duyurusu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Calisan-temsilcisi-secim-duyurusu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Seçim sonuçları",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Secim-sonucları',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Çalışan temsilcisi ataması",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Calisan-temsilcisi-atamasi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Çalışan temsilcisi eğitimi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Calisan-temsilcisi-egitimi',
                        level: 2,
                        subcards: []
                    },
                    ]
                },
                {
                    id: 3,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Destek Elemanı Ataması",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Destek-Elemani-Atamasi',
                        level: 2,
                        subcards: []
                    },
                    ]
                },
            ]
        },

        {
            id: 2,
            name: "Risk Değerlendirme Analizi",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Risk-Degerlendirme-Analizi',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Risk Değerlendirme Ekibi Atama Yazısı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Risk-Degerlendirme-Ekibi-Atama-Yazisi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Risk Değerlendirme Ekibi Eğitimi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Risk-Degerlendirme-Ekibi-Egitimi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Risk değerlendirme analizi prosedürü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Risk-degerlendirme-analizi-proseduru',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Risk değerlendirme analizi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Risk-degerlendirme-analizi',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 3,
            name: "Acil Durum Eylem Planı",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Acil-Durum-Eylem-Plani',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Acil durum ekipleri atama yazısı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Acil-durum-ekipleri-atama-yazisi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Acil durum ekipleri eğitimi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Acil-durum-ekipleri-egitimi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Acil durum eylem planı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Acil-durum-eylem-plani',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Acil durum tatbikatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Acil-durum-tatbikati',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 4,
            name: "İSG Kurulu",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/ISG-Kurulu',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "İç Yönerge",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Ic-Yonerge',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "İSG Kurul Prosedürü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/ISG-Kurul-Proseduru',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Kurul Üyeleri Atama yazısı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kurul-Uyeleri-Atama-yazisi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Kurul Üyeleri Eğitimi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kurul-Uyeleri-Egitimi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "İSG Kurul Toplantısı Çağrı Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/ISG-Kurul-Toplantisi-Cagri-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 5,
                        name: "İSG Kurul Toplantısı Katılım Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/ISG-Kurul-Toplantisi-Katilim-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 6,
                        name: "İSG Kurul Toplantısı Karar Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/ISG-Kurul-Toplantisi-Karar-Formu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 5,
            name: "İSG Eğitimleri",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/ISG-Egitimleri',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Temel İSG Eğitimleri",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Temel-ISG-Egitimleri',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "İSG Eğitimi Soruları",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/ISG-Egitimi-Sorulari',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "İşe Başlama Eğitimleri",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Ise-Baslama-Egitimleri',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Toolbox Eğitimleri",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Toolbox-Egitimleri',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 6,
            name: "İş Kazası - Ramakkala",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Is-Kazasi-Ramakkala',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "İş kazası tutanağı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-kazasi-tutanagi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "İş kazası SGK Bildirim formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-kazasi-SGK-Bildirim-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Ramakkala Olay Tutanağı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Ramakkala-Olay-Tutanagi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "İş Kazası sonrası eğitim formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-Kazasi-sonrasi-egitim-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "Kaza Kök-Neden Analizi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kaza-Kok-Neden-Analizi',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 7,
            name: "Uygunsuzluk Formları",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Uygunsuzluk-Formlari',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Düzeltici ve önleyici tutanak formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Duzeltici-ve-onleyici-tutanak-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Uyarı ve Bilgilendirme Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Uyari-ve-Bilgilendirme-Formu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 8,
            name: "Takip - Kontrol Formları",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Takip-Kontrol-Formlari',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Yangın Tüpleri kontrol formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yangin-Tupleri-kontrol-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Servis Araçları kontrol formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Servis-Araclari-kontrol-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Acil çıkış kapıları ve ekipmanları kontrol formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Acil-cikis-kapilari-ve-ekipmanlari-kontrol-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Saha kontrol formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Saha-kontrol-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "Mutfak - Yemekhane kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Mutfak-Yemekhane-kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 5,
                        name: "Revir Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Revir-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 6,
                        name: "İskele kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Iskele-kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 7,
                        name: "İş Makinaları kontrol formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-Makinalari-kontrol-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 8,
                        name: "İş Ekipmanları Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-Ekipmanlari-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 9,
            name: "KKD Formları",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/KKD-Formlari',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "KKD standartlar uygunluk tablosu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/KKD-standartlar-uygunluk-tablosu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "KKD Zimmet Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/KKD-Zimmet-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "KKD Matrisi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/KKD-Matrisi',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 10,
            name: "Periyodik Kontrol Formları",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Periyodik-Kontrol-Formlari',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Elektrik iç Tesisatı periyodik kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Elektrik-ic-Tesisati-periyodik-kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Topraklama tesisatı periyodik kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Topraklama-tesisati-periyodik-kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Jeneratör Periyodik kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Jenerator-Periyodik-kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Kompresör ve Hava tankları Periyodik Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kompresor-ve-Hava-tanklari-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "Asansör Periyodik Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Asansor-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 5,
                        name: "Havalandırma Periyodik Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Havalandirma-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 6,
                        name: "Yangın Tesisatı Periyodik Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yangin-Tesisati-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 7,
                        name: "Paratoner Periyodik Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Paratoner-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 8,
                        name: "Kazan Periyodik Kontrol Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kazan-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 9,
                        name: "Kaldırma Araçları Periyodik Kontrol Formu (Forklift, Vinç, Tavan Vinci vb.)",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kaldırma-Araclari-Periyodik-Kontrol-Formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 10,
                        name: "Revir malzemeleri kalibrasyon ölçümleri",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Revir-malzemeleri-kalibrasyon-olcumleri',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 11,
                        name: "Kullanım ve atık suyu analizleri",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kullanim-ve-atik-suyu-analizleri',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 11,
            name: "İş Hijyeni Ölçümleri (Ortam ve Kişisel Maruziyet Ölçümleri)",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Is-Hijyeni-Olcumleri',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Ortam toz ve kişisel maruziyet ölçümü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Ortam-toz-ve-kisisel-maruziyet-olcumu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Ortam gürültü ve kişisel maruziyet ölçümü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Ortam-gurultu-ve-kisisel-maruziyet-olcumu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Termal Konfor ölçümü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Termal-Konfor-olcumu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Aydınlatma Ölçümü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Aydinlatma-Olcumu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "Elektromanyetik Alan Ölçümü",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Elektromanyetik-Alan-Olcumu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 12,
            name: "İş İzinleri",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Is-Izinleri',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Genel Çalışma iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Genel-Calisma-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Sıcak Çalışma iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Sicak-Calisma-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Yüksekte çalışma iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yuksekte-calisma-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Soğuk Çalışma iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Soguk-Calisma-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "Kapalı alanda çalışma iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kapali-alanda-calisma-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 5,
                        name: "Gece Çalışması iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Gece-Calismasi-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 6,
                        name: "Elektrik çalışması iş izin formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Elektrik-calismasi-is-izin-formu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 13,
            name: "Sağlık",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Saglik',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "İşe giriş muayene formu (Ek-2)",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Ise-giris-muayene-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Revir ilaç kontrol formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Revir-ilac-kontrol-formu',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Gizlilik esaslı İşe Giriş Periyodik Muayene Bildirim Formu",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Gizlilik-esasli-Ise-Giris-Periyodik-Muayene-Bildirim-Formu',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 14,
            name: "Talimatlar",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Talimatlar',
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Genel İş Güvenliği Talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Genel-Is-Guvenligi-Talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Elle kaldırma ve taşıma talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Elle-kaldirma-ve-tasima-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Yüksekte çalışma talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yuksekte-calisma-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Elektrik işleri talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Elektrik-isleri-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 4,
                        name: "İş makinaları talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Is-makinalari-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 5,
                        name: "KKD talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/KKD-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 6,
                        name: "Vinç Kullanma talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Vinc-Kullanma-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 7,
                        name: "Jeneratör kullanma talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Jenerator-kullanma-talimati',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 8,
                        name: "Basınçlı tüpler kullanma talimatı",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Basincli-tupler-kullanma-talimati',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },
        {
            id: 15,
            name: "Diğer Formatlar",
            description: "Kart ile ilgili detaylar burada gözükecek.",
            link: '/Diger-Formatlar',
            level: 0,
            subcards: [
                {
                    id: 0,
                    name: null,
                    level: 1,
                    subcards : [
                    {
                        id: 0,
                        name: "Mesleki yeterlilik belgesi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Mesleki-yeterlilik-belgesi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 1,
                        name: "Kimyasal Maddelerin GBF belgeleri",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Kimyasal-Maddelerin-GBF-belgeleri',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 2,
                        name: "Hijyen Belgesi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Hijyen-Belgesi',
                        level: 2,
                        subcards: []
                    },
                    {
                        id: 3,
                        name: "Yüksekte çalışma belgesi",
                        description: "Kart ile ilgili detaylar burada gözükecek.",
                        link: '/Yuksekte-calisma-belgesi',
                        level: 2,
                        subcards: []
                    },
                    ]
                }   
            ]
        },

    ];

    return(
        <>
            {location.state && <div className="firm-header">{location.state[1]}</div>}
            
            {Cards.map((card) => {
                return(
                    <Col className="container-cards" key={"Col-"+card.id}>
                        <Card key={card.id} className="Cards">
                            <Card.Body>
                                <Card.Title>{card.name}</Card.Title>
                                <Card.Text>
                                {card.description}
                                </Card.Text>
                                {/* <Card.Text>Son güncellenme Tarihi {card.dateofUpdate}</Card.Text> */}
                                <Button as={Link}
                                    to={card.link} 
                                    state={[location.state[0],location.state[1], card]}
                                    variant="primary">Detaylar
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            })}
        </>
    );
}
import { Storage } from "aws-amplify";

const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

const downloadFile = async(key, title, subtitle) => {
    const result = await Storage.list(`ISG_Library/${key["Konu_Basligi"]}/${key["Alt_Konu_Basligi"]}/${key["Baslik"]}/${key["Dokuman"]}`, { pageSize : 'ALL' });
    const result2 = await Storage.get(result["results"][0]["key"], { download: true});
    downloadBlob(result2.Body, key["Dokuman"]);
}

export const CreateLink = (key, title, subtitle, index) => {

    if(key["Link"] !== null){
        return(
            <td key={index+"-"+key["Link"]}><a target="_blank" rel="noopener noreferrer" href={key["Link"]}>Aç</a>{'\n'}</td>
        )
    }
    else if(key["Dokuman"] !== null){
        return(
            <td key={index+"-"+key["Dokuman"]}><a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}  onClick={() => downloadFile(key, title, subtitle)}>İndir</a></td>
        )
    }
    else{
        return(
            <>--</>
        )
    }
 }
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Table, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Model_Calisan, Model_GetAllCompanies, Model_UserComp } from '../../../../api/Model_Read';
import { UserCompany } from '../../../../models';
import { DataStore } from 'aws-amplify';

export const ListUser = () => {

    const navigate = useNavigate();
    const [ model, setModel ] = React.useState(null);
    const [ selectedUser , setSelectedUser ] = React.useState(null);
    const [ show, setShow ] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [ AllComp, setAllComp ] = React.useState(null);
    const [ usercomp, setUsercomp ] = React.useState(null);
    const [ ComplListforModal, setComplListforModal ] = React.useState(null);

    React.useEffect(()=>{
        async function getModelData(){
            setModel(await Model_Calisan())
            setAllComp(await Model_GetAllCompanies())
            setUsercomp(await Model_UserComp())
        }
        getModelData() 
     },[]);

     async function submitValues (values) {
        values.preventDefault();
    };

    const opencheckboxlist = (key) => {
        setSelectedUser(key)
        const infoDict = {}

        const usercheck = (userID, companyID) => {
            for(let i=0; i<usercomp.length; i++){
                if(usercomp[i].userID === userID && usercomp[i].companyID === companyID)
                    return true
            }
            return false
        }
        AllComp.map(comp => infoDict[comp.id] = {companyname: comp.name, user : key.id, checked: usercheck(key.id, comp.id), company: comp.id, willUpdated: false })
        setComplListforModal(infoDict)
    }

    const changeCheckedValue = (companyID) => {
        const newData = {...ComplListforModal}
        newData[companyID].checked = !newData[companyID].checked
        newData[companyID].willUpdated = !newData[companyID].willUpdated
        setComplListforModal(newData)
    }
    function refreshPage() {
        window.location.reload(false);
      }

    const update = async() => {
        (Object.keys(ComplListforModal)).map(async(key) => {
            if(ComplListforModal[key].willUpdated === true){
                if(ComplListforModal[key].checked === true){
                    await DataStore.save(new UserCompany({
                            userID: selectedUser.id,
                            companyID: ComplListforModal[key].company
                        }))
                }
                else if(ComplListforModal[key].checked === false){
                    const toDelete = await DataStore.query(UserCompany, (c) => c.and((c) => [c.userID.eq(selectedUser.id), c.companyID.eq(ComplListforModal[key].company)]));
                    DataStore.delete(toDelete[0]);
                }
            refreshPage()
        }else return null})
    }
    
    return(
        <>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={submitValues}>
                    <Modal.Header closeButton>
                        <Modal.Title>Firmaları güncelle</Modal.Title>
                    </Modal.Header>
                            {ComplListforModal && (Object.keys(ComplListforModal)).map((c,index) => 
                                {return (<Modal.Body key={ComplListforModal[c].user + ComplListforModal[c].company}><Form.Check 
                                            id={ComplListforModal[c].company}
                                            label={ComplListforModal[c].companyname}
                                            defaultChecked={ComplListforModal[c].checked}
                                            onChange={() => changeCheckedValue(c)}
                                            
                                            /></Modal.Body>)})}
                    <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={() => update()}>
                        Değişiklikleri kaydet
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Container fluid className='container-custom'>
            <Row className='heighting'>
                    <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col>
                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>
                            <h6>Kullanıcı Kaydı</h6>
                        &ensp;
                        <Row>
                            <Col>
                                <Button onClick={() => navigate("/Yeni-Kullanici-Ekle")}>Ekle
                                </Button>
                            </Col>
                            <Col>
                                <Button disabled>Düzenle
                                </Button>
                            </Col>
                            <Col>
                                <Button disabled>Sil
                                </Button>
                            </Col>
                        </Row>
                        &ensp;
                        </Row>

                        <Row>
                            <Table striped bordered hover size="sm" style={{fontSize:13}}>
                            <thead>
                                <tr>
                                    <th>TC Kimlik No</th>
                                    <th>Adı</th>
                                    <th>Soyadı</th>
                                    <th>Yetki</th>
                                    <th>Çalışma Durumu</th>
                                    <th>Fabrikalar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {model && model.map((key,index) => {
                                    return(
                                    <tr key={index}>
                                        <td>{key.TC_Kimlik_No}</td>
                                        <td>{key.Name}</td>
                                        <td>{key.Surname}</td>
                                        <td>{key.Group}</td>
                                        {key.Calisma_Durumu ? (
                                            <td>Aktif</td>
                                        ) : (
                                            <td>Aktif değil</td>
                                        )}
                                        <td>{key.companies.sort().map(c => c[0]).join(', ')}</td>
                                        <td><Button onClick={() => { opencheckboxlist(key); handleShow()}}>Firma Ekle</Button></td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>

                        </Row>
                    </Col>
                    
                    <Col></Col>
                </Row>
            </Container>
        </>
    )
}
import './App.css';
import { Amplify, Hub, DataStore } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './theme/Styles.css';
import * as React from 'react';
import { Navigations } from './navigation/Navigation_Main';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('tr');

I18n.putVocabularies({
    tr: {
        'Enter your Username': 'Kullanıcı adını giriniz',
        'Enter your Password': "Şifrenizi giriniz",
        'Incorrect username or password.' : 'Yanlış kullanıcı adı veya şifre',
        'Reset Password': 'Şifre Yenile'
    },
  });

Amplify.configure(awsconfig);

function App() {

    React.useEffect(() => {
        Hub.listen('auth', (data) => {
        const { payload } = data;
            if(payload.event==="signIn"){
                DataStore.start()
            }
        })
    },[])
   
    return (
        <Authenticator hideSignUp={true}>{({ signOut }) =>  <Navigations data={signOut}/> }</Authenticator>
    );
}

export default App;
// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Form, Company, Document, User, ModelISGKutuphane, UserCompany } = initSchema(schema);

export {
  Form,
  Company,
  Document,
  User,
  ModelISGKutuphane,
  UserCompany
};
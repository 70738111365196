import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Model_Form } from '../../api/Model_Read';
import { CreateCards } from '../../components/Common/Cards';
import { updateGroupAndAdminStatus, checkAdminGroup} from '../../components/Common/CheckGroup';

export const ListCards = () => {

    let location = useLocation();
    const navigate = useNavigate()

    const [ infos, setInfos ] = React.useState({
        model: null,
        group: null,
        //isAdmin: false,
        Firm: {
            id  : location.state[0],
            name : location.state[1]
        },
        userID: null,
        cards : location.state[2],
        cardID: location.state[3].toString()+"."+location.state[4].toString()+"."+location.state[2].id.toString()
    })

    React.useEffect(()=>{
        updateGroupAndAdminStatus(setInfos);

        async function getModelData(){
            try{
                const userID = await Auth.currentSession()
                setInfos((prevState) => ({...prevState, userID: userID.idToken.payload.sub}))
                const model = await Model_Form(infos)
                setInfos((prevState) => ({...prevState, model: model}))
            } catch (e) {
                console.log(e)
            }
            
        }
        getModelData()
    },[infos.group, infos.userID]);
  
    return(
        <>
            <Container fluid className='container-custom'>
                <Row className='heighting'>
                    <Col><Button variant="primary" onClick={() => navigate(-1)}>Geri dön</Button></Col>

                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>
                            <Col sm={8}>
                                <div className="firm-header">{infos.Firm.name}</div>
                                <hr/>
                                <div className="firm-header2">{location.state[5]}</div>
                                <hr/>
                                <div className="firm-header3">{infos.cards.name}</div>
                                <hr/>
                            </Col>
                            <Col>
                            {
                             checkAdminGroup(infos.group) ? (
                                    <Button as={Link} 
                                        to={"/Yeni-Form-Yukle"} 
                                        state={[infos]}
                                        variant="primary">Yeni form yükle
                                    </Button>
                                ) : (
                                <></>
                            )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>{CreateCards(infos)}</Col>                            
                        </Row>                              
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </>
    )
}
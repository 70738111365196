import { DataStore, Storage }   from 'aws-amplify';
import { Form, Document } from '../models';

async function Model_FormSend (infos, valuesList, timeID) {

    try{
        const form = await DataStore.save(
            new Form({
                "createdDate"       : valuesList[0],
                "cardID"            : infos.location.state[0].cardID,
                "serialNo"          : parseInt(valuesList[1]),
                "userID"            : infos.subID,
                "companyID"         : infos.location.state[0].Firm.id,
            })
        );

        await Storage.put(`${infos.location.state[0].cardID}/${infos.subID}/${timeID}/${infos["uploadFile"].name}`, infos["uploadFile"], { level: "protected" });

        await DataStore.save(
                    new Document({
                        "name"      : infos["uploadFile"].name,
                        "path"      : `${infos.location.state[0].cardID}/${infos.subID}/${timeID}/${infos["uploadFile"].name}`,
                        "link"      : null,
                        "formID"    : form.id,
                        "formType"  : infos.location.state[0].cardID,
                    })
                )

    } catch(e){
        console.log(e)
    }
}

export {

    Model_FormSend
}
import { Modal, Button } from "react-bootstrap"


export const CreateFirmModal = (show, handleClose) => {

    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Yeni firma kaydı girildi!</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Kapat
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const FirmChoicePage = () => {

    return(
        <>
            <Container fluid className='container-custom'>
                <Row className='heighting'>
                    <Col></Col>
                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>

                        </Row>
                        <Row>
                            <Col></Col>
                            <Col>
                                <Nav.Link className="Button_Main" as={Link} 
                                        to={{
                                            pathname: "/Firmalar",
                                            }} >
                                        Firmalar
                                        </Nav.Link>
                            </Col>
                            <Col></Col>
                            <Col className="justify-content-md-center">
                                <Nav.Link className="Button_Main" as={Link} 
                                        to={{
                                            pathname: "/ISG-Kutuphanesi",
                                            }} >
                                        ISG Kütüphanesi
                                        </Nav.Link>

                            </Col>
                            <Col></Col>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>

            </Container>
        </>
    )

}

export default FirmChoicePage;
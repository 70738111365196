import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";

export const ListCardsFirstLevel = () => {

    let location = useLocation();

    const [ infos, setInfos ] = React.useState({
        isAdmin: false,
        navigate: useNavigate(),
        Firm: {
            id  : location.state[0],
            name : location.state[1]
        },
        cards : location.state[2],
        level1: location.state[2].id,
        level2: null
    })

    return(
        <>
            <Container fluid className='container-custom'>
                <Row className='heighting'>
                    <Col><Button variant="primary" onClick={() => infos.navigate(-1)}>Geri dön</Button></Col>
                    
                    <Col sm={7} className='main-col-custom mt-3'>
                        <Row>
                        {infos.Firm.name && <div className="firm-header">{infos.Firm.name}</div>}
                        <hr/>
                    {infos.cards && <div className="firm-header2">{infos.cards.name}</div>}
                    <hr/>
                    {infos.cards.subcards.map((card) => {
                        if(card.name == null){
                            return(
                                card.subcards.map(c => {
                                return(
                                    <Col className="container-cards" key={"Col-"+c.id}>
                                        <Card key={c.id} className="Cards">
                                            <Card.Body>
                                                <Card.Title>{c.name}</Card.Title>
                                                <Card.Text>
                                                {c.description}
                                                </Card.Text>
                                                <Button as={Link}
                                                    to={c.link} 
                                                    state={[location.state[0],location.state[1], c, infos.level1, card.id, infos.cards.name]}
                                                    variant="primary">Detaylar
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    )
                                })
                            )
                        }
                        else{
                            return(
                            <Col className="container-cards" key={"Col-"+card.id}>
                                <Card key={card.id} className="Cards">
                                    <Card.Body>
                                        <Card.Title>{card.name}</Card.Title>
                                        <Card.Text>
                                        {card.description}
                                        </Card.Text>
                                        <Button as={Link}
                                            to={card.link} 
                                            state={[location.state[0],location.state[1], card, infos.level1, card.id, infos.cards.name]}
                                            variant="primary">Detaylar
                                        </Button>
                                    
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                        }

                        
                    })}
                    </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </>
    )
}
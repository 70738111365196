import { Modal, Button } from "react-bootstrap"

export const FormSendInfo = (show, handleClose, messageTitle, message) => {

    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{messageTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Kapat
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
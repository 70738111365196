import { DataStore, SortDirection, Predicates } from 'aws-amplify/lib-esm';
import { User, Company, Form, UserCompany, ModelISGKutuphane } from '../models';

async function Model_Calisan (){
    const UserModel = await DataStore.query(User)
    const UserCompanyModel = await DataStore.query(UserCompany);
    const CompanyModel = await DataStore.query(Company);

    let UserModelCopy = JSON.parse(JSON.stringify(UserModel));

    for(let i=0;i<UserModelCopy.length;i++){
        const companiesIDlist = UserCompanyModel.filter(uc => uc.userID === UserModelCopy[i].id).map(result => result.companyID)
        const companiesNamelist = companiesIDlist.map(c1 => {return CompanyModel.filter(c => c.id === c1).map(c => [c.name, c.id])})
        UserModelCopy[i].companies =  [].concat(...companiesNamelist)
    }

    return UserModelCopy
}

async function Model_UserComp (){
    const model = await DataStore.query(UserCompany);
    return model;
}

async function Model_User (identity){
    const model = await DataStore.query(User, (c) => c.Identity_ID.eq(identity), {
        })
    return model;
}

async function Model_GetAllCompanies (){
    const model = await DataStore.query(Company);
    return model;
}

async function Model_Fabrikalar (user){
    const companies = (await DataStore.query(UserCompany)).filter(op => op.userID === user).map(op => op.companyID);
    if(companies.length>0)
        return await DataStore.query(Company, (c) => c.or((c) => companies.map(id => c.id.eq(id))), {
            sort: s => s.name(SortDirection.ASCENDING)});
    else return []
}

async function Model_Form (infos){
    const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.Firm.id), c.cardID.eq(infos.cardID)]),{
        sort: s => s.createdAt(SortDirection.DESCENDING)
        });

    return modelForms

}

// const Common = async(modelForms, modelname,frommodelId) => {

//     const ids = []
//     const docids = []

//     for(let i=0;i<modelForms.length;i++){
//         ids.push(modelForms[i][frommodelId])
//         docids.push(modelForms[i].id)
//     }

//     if(ids.length!==0){
//         const model = await DataStore.query(modelname, (c) => c.or((c) => ids.map(id => c.id.eq(id))))      
//         return model;
//     }
//     else{
//         return [];
//     }
// }

// async function Model_AcilDurumTatbikati (infos){

//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelADTId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelADT, "formModelADTId")

// }

// async function Model_AksiyonTakipCizelgesi (infos){
//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelATCId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelATC, "formModelATCId")

// }

// async function Model_BasincliKapMuayeneleri (infos){
//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelBKMId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelBKM, "formModelBKMId")
// }

// async function Model_Forklift (infos){
//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelFId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelF, "formModelFId")
// }

async function Model_ISGKutuphane(title){
    const model = await DataStore.query(ModelISGKutuphane, c => c.Alt_Konu_Basligi.eq(title), Predicates.ALL,);
    return model;
}

// async function Model_RiskDegerlendirme (infos){
//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelRDId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelRD, "formModelRDId")
// }

// async function Model_ISGegitim (infos){
//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelIEId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelIE, "formModelIEId")

// }

// async function Model_TopraklamaTesti (infos){
//     const modelForms = await DataStore.query(Form, (c) => c.and(c => [c.companyID.eq(infos.FirmInfo[0]), c.formModelTTId.ne(null)]),{
//         sort: s => s.createdAt(SortDirection.DESCENDING)
//         });

//     return Common(modelForms, ModelTT, "formModelTTId")
// }


export {
    Model_Fabrikalar,
    Model_User,
    Model_Calisan,
    Model_GetAllCompanies,
    Model_UserComp,
    Model_Form,
    Model_ISGKutuphane
}